import Image from 'next/image';

import { InputBase, styled } from '@mui/material';

import { FlexRowCenter } from 'libs/components/flex-box';

export const StyledBanner = styled(FlexRowCenter)(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  textTransform: 'capitalize',
  position: 'relative',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

  [theme.breakpoints.down('sm')]: {
    padding: '16% 0',
  },
}));
export const StyledInput = styled(InputBase)(() => ({
  border: 'none',
  padding: '1%',
  background: 'transparent',
  width: '100%',
  height: '100%',
  outline: 'none',
}));

export const StyledImage = styled(Image)(() => ({
  border: 'none',
  padding: '1%',
  background: 'transparent',
  width: '100%',
  height: '100%',
  outline: 'none',
  zIndex: 1,
  ':hover': {
    opacity: 0.4,
    cursor: 'pointer',
    transition: '0.4s ease-in-out',
  },
  ':hover + #tagDiv': {
    transition: '0.4s ease-in-out',
    display: 'block',
    borderRadius: 'none',
  },
}));

export const StyledChip = styled('span')(({ theme }) => ({
  borderRadius: '0.25rem',
  border: '1px solid #D3D3D3',
  padding: '0.25rem 0.75rem',
  color: theme.palette.info.main,
  background: '#FFF',
}));

export const StyledVideo = styled('video')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    scale: '2.4',
  },
}));

export const SearchText = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
