'use client';
import { FC, Fragment, Suspense, useState } from 'react';

import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import useAuth from 'hooks/useAuth';
import useDialougeToggler from 'hooks/useDialougeToggler';
import { fetchUserData } from 'hooks/useDownload';
import ProfileDropDown from 'libs/components/drop-downlist';
import { navLinks } from 'libs/helpers/constants/navbar';
import { setClarityEvent } from 'libs/helpers/set-clarity-events';
import FlexBox from '../flex-box/flex-box';
import { MegaMenu } from '../mega-menu';
import SearchBarWithCategory from '../SearchBarWithCatergory';
import {
  LicenseButton,
  LogoMobile,
  NavBar,
  SignupButton,
  StyledNavLink,
  StyledNavLink2,
} from './style';

const HeaderNav: FC = () => {
  const { state, dispatch: logoutDispatch } = useAuth();
  const { dispatch } = useDialougeToggler();
  const router = useRouter();
  const [isPremium, setIsPremium] = useState(false);
  const accesstoken = Cookies.get('accesstoken');

  useQuery<any>(['userDetails', accesstoken], fetchUserData, {
    onError: (err: any) => {
      if (err?.response?.status === 401) {
        Cookies.remove('accesstoken');
      }
    },
    onSuccess: (data) => {
      if (
        data?.data?.userDetails?.name === 'PREMIUM' ||
        data?.data?.userDetails?.name === 'PRO'
      ) {
        setIsPremium(true);
      }
    },
    retry: false,
    enabled: !!accesstoken,
  });

  const LogoutHandler = () => {
    Cookies.remove('accesstoken');

    setClarityEvent('logout_clicked', 'confirmed');
    logoutDispatch({
      type: 'LOGOUT',
    });
  };

  const LARGE_PAGE_VIEW = () => {
    return (
      <Fragment>
        <FlexBox
          alignItems={'flex-start'}
          height={{ xs: '4rem', sm: '6rem' }}
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          flexDirection={'column'}
        >
          <FlexBox
            justifyContent={'space-between'}
            height={'100%'}
            width={'100%'}
            alignItems={'center'}
            gap={2}
          >
            <FlexBox alignItems={'center'} gap={2}>
              <Link
                href="/"
                style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}
              >
                <LogoMobile
                  height={0}
                  width={0}
                  src={`${process.env.CONTENT_BASE_URL}/newLogo.png`}
                  alt="brand-logo"
                />
              </Link>
              <Suspense fallback={<div>Loading...</div>}>
                <SearchBarWithCategory />
              </Suspense>
            </FlexBox>
            {state.user.email ? (
              <FlexBox alignItems={'center'} gap={2}>
                <LicenseButton
                  disabled={isPremium}
                  onClick={() => {
                    if (!isPremium) {
                      router.push('/pricing');

                      return;
                    }
                  }}
                  sx={{
                    cursor: isPremium ? 'default' : 'pointer',
                  }}
                >
                  {isPremium ? 'Premium user' : 'Get license'}
                </LicenseButton>
                <ProfileDropDown
                  user={state.user}
                  LogoutHandler={LogoutHandler}
                />
              </FlexBox>
            ) : (
              <SignupButton
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE',
                    payload: {
                      toggled: true,
                      element: 'LoginForm',
                      closeBtn: true,
                    },
                  });

                  setClarityEvent('login_clicked', 'confirmed');
                }}
              >
                Login
              </SignupButton>
            )}
          </FlexBox>
          <FlexBox>
            <NavBar>
              {navLinks.map((item, ind) => (
                <MegaMenu
                  key={ind}
                  path={item.path}
                  title={item.title}
                  menuList={item.childrens}
                />
              ))}
            </NavBar>
            <StyledNavLink
              href={'/pricing'}
              sx={{ borderRight: '2px solid #d5d5d5', paddingX: '1.2rem' }}
            >
              Pricing
            </StyledNavLink>
            <StyledNavLink2
              href={'/learn'}
              sx={{ borderRight: '2px solid #d5d5d5', paddingX: '1.2rem' }}
            >
              Learn
            </StyledNavLink2>
            <StyledNavLink
              href={'/affiliate'}
              sx={{ borderRight: '2px solid #d5d5d5', paddingX: '1.2rem' }}
              prefetch={false}
            >
              Become an affiliate
            </StyledNavLink>
            {state?.user?.role?.toLowerCase() !== 'author' ? (
              <StyledNavLink
                href={'/sell-your-content'}
                sx={{ paddingX: '1rem' }}
                prefetch={false}
              >
                Sell your content
              </StyledNavLink>
            ) : (
              <StyledNavLink
                href={'/author'}
                sx={{ paddingX: '1rem' }}
                prefetch={false}
              >
                View Dashboard
              </StyledNavLink>
            )}
          </FlexBox>
        </FlexBox>
      </Fragment>
    );
  };

  return (
    <>
      <Fragment> {LARGE_PAGE_VIEW()}</Fragment>
    </>
  );
};

export default HeaderNav;
