'use client';

import { useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import Star from '@mui/icons-material/Star';
import { Box, Stack, Typography } from '@mui/material';

import { FlexBox } from 'libs/components/flex-box';
import { setClarityEvent } from 'libs/helpers/set-clarity-events';
import { DialogDrawer } from '../dialog-drawer';

const UserSubscriptionCreation = () => {
  const { push } = useRouter();
  const benefitDetails = [
    {
      title: 'Unlimited Premium design files daily',
    },
    {
      title: 'Unlimited free design files daily',
    },
    {
      title: 'Commercial licensing',
    },
    {
      title: 'Exclusive access to new releases',
    },
    {
      title: 'Advanced file formats',
    },
    {
      title: 'Dedicate customer support',
    },
  ];

  useEffect(() => {
    setClarityEvent('subscription-confirmation', 'confirmed');
  }, []);

  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {
        push('/');
      }}
      wrapperStyle={{ height: '100%', maxWidth: '50rem', width: '100%' }}
    >
      <Stack
        alignItems={'center'}
        zIndex={99}
        justifyContent={'center'}
        sx={{
          minHeight: '20dvh',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        padding={'2rem'}
      >
        <Image
          src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/Images/Popup-Images/HDStockImages 3.svg`}
          alt="Success"
          width={150}
          height={150}
        />

        <Stack spacing={3} position={'relative'}>
          <Typography
            variant="h5"
            align="center"
            fontSize={{ md: '1.5rem', xs: '1.2rem' }}
            fontStyle={'normal'}
            fontWeight={500}
            lineHeight={'normal'}
          >
            Welcome aboard!
            <br />
            <span
              style={{
                background:
                  'linear-gradient(90deg, #6631FB 34.77%, #FF0DBB 73.54%)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '1.2rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }}
            >
              Your subscription is confirmed
            </span>
          </Typography>

          <Stack justifyContent={'center'} alignItems={'center'}>
            <Box>
              {benefitDetails.map((benefit) => (
                <FlexBox
                  key={benefit.title}
                  alignSelf={{ lg: 'center', xs: 'center' }}
                  gap={{ xs: '1.2rem', md: '1rem' }}
                >
                  <Box width={'1vw'}>
                    <Star
                      sx={{
                        color: '#FFD401',
                      }}
                    />
                  </Box>
                  <Box>{benefit.title}</Box>
                </FlexBox>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </DialogDrawer>
  );
};

export default UserSubscriptionCreation;
