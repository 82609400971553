'use client';
import React, { useEffect } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

import LoginContextProvider from 'contexts/LoginContext';
import ToogleDialogProvider from 'contexts/ToogleDialog';
import { setClarityEvent } from 'libs/helpers/set-clarity-events';
import ThemeProvider from 'theme/theme-provider';
import { DialougeHandler } from '../dialougeHandler';
import HomeLayout from '../layouts/home';

const queryClient = new QueryClient();

export default function LayoutProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    let fingerPrint: string | null | undefined;

    fingerPrint = localStorage.getItem('usr_token');
    fingerPrint = Cookies.get('usr_token');

    if (!fingerPrint) {
      // eslint-disable-next-line import/no-named-as-default-member
      const fpPromise = FingerprintJS.load();

      fpPromise.then((fp) => {
        fp.get().then((res) => {
          const visitorId = res.visitorId;

          localStorage.setItem('usr_token', visitorId);
          Cookies.set('usr_token', visitorId);
        });
      });
    }
  }, []);

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      setClarityEvent(
        'application-error',
        event?.error?.message || event?.message || ''
      );
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      setClarityEvent(
        'unhandledrejection-application-error',
        event.reason?.message || 'Unhandled promise rejection'
      );
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener(
        'unhandledrejection',
        handleUnhandledRejection
      );
    };
  }, []);

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ToogleDialogProvider>
          <LoginContextProvider>
            <HomeLayout>{children}</HomeLayout>
            <DialougeHandler />
          </LoginContextProvider>
        </ToogleDialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
