'use client';
import React from 'react';

import { Box } from '@mui/material';

import { FlexBoxCentered } from 'libs/components/Flex/flex-box';

interface PopupLayoutProps {
  children: React.ReactNode;
  height?: string;
  active: string;
}

export default function PopupLayout({ children }: PopupLayoutProps) {
  return (
    <>
      <FlexBoxCentered alignItems={'center'} justifyContent={'center'}>
        <Box
          display={{
            sm: 'flex',
          }}
        >
          <Box
            paddingY={'3%'}
            width={{ md: '40rem' }}
            display={'flex'}
            justifyContent={{ md: 'center', xs: 'start' }}
            style={{ overflow: 'scroll' }}
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </FlexBoxCentered>
    </>
  );
}
