import { Components } from '@mui/material/styles/components';

import { dark, grey, primary, secondary, success } from './theme-colors';
import { typography } from './typography';

// ========================================================
declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsColorOverrides {
    dark: true;
    paste: true;
    marron: true;
  }

  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsSizeOverrides {
    normal: true;
  }
}

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        scrollBehavior: 'smooth',
      },
      html: {
        scrollBehavior: 'smooth',
      },
      p: {
        lineHeight: 1.75,
      },
      button: {
        fontSize: 14,
        fontFamily: typography.fontFamily,
      },
      '.MuiRating-sizeSmall': {
        fontSize: '20px',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      '::-webkit-scrollbar': {
        display: 'none',
      },
      ' .Toastify__toast-icon svg': {
        fill: '#ffffff',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: { zIndex: 0 },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: { borderRadius: 8 },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: { borderRadius: '8px' },
    },
  },
  MuiPagination: {
    defaultProps: {
      variant: 'outlined',
      color: 'primary',
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: { paddingTop: 8, paddingBottom: 8 },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '& .secondary': { opacity: 0.4 },
      },
    },
  },
  MuiTextField: {
    defaultProps: { size: 'small', variant: 'outlined' },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.color === 'info' && {
          '& .MuiOutlinedInput-root': { borderRadius: '8px', fontWeight: 600 },
          '& .MuiOutlinedInput-notchedOutline': { borderColor: grey[300] },
        }),
      }),
    },
  },

  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        minWidth: 0,
        minHeight: 0,
        fontWeight: 600,
        textTransform: 'capitalize',
        ...(ownerState.color === 'info' && { borderRadius: '8px' }),
        ...(ownerState.color === 'dark' && {
          color: '#fff',
          borderRadius: 0,
          transition: 'all 0.3s',
          ':hover': { backgroundColor: '#343434' },
        }),
        ...(ownerState.color === 'dark' &&
          ownerState.variant === 'outlined' && {
            color: dark.main,
            borderRadius: '3px',
            transition: 'all 0.3s',
            ':hover': { backgroundColor: dark.main, color: 'white' },
          }),
      }),
      sizeLarge: { padding: '.6rem 2.5rem' },
    },
    defaultProps: { color: 'inherit', variant: 'contained' },
  },

  MuiChip: {
    defaultProps: { color: 'primary' },
    styleOverrides: {
      labelSmall: { paddingInline: 12 },
      colorSuccess: { color: success.main, backgroundColor: success[100] },
      colorPrimary: { color: primary[500], backgroundColor: primary[100] },
      colorSecondary: {
        color: secondary[500],
        backgroundColor: secondary[100],
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: '1.5rem',
        fontWeight: 600,
        padding: 0,
        margin: 0,
        '@media (min-width: 600px)': {
          fontSize: '1.5rem',
        },
        '@media (min-width: 960px)': {
          fontSize: '2.2rem',
        },
      },
      h2: {
        fontSize: '1.2rem',
        fontWeight: 600,
        margin: 0,
        padding: 0,
        '@media (min-width: 600px)': {
          fontSize: '1.8rem',
        },
        '@media (min-width: 960px)': {
          fontSize: '2.5rem',
        },
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: 0,
        padding: 0,
        '@media (min-width: 600px)': {
          fontSize: '1.5rem',
        },
        '@media (min-width: 960px)': {
          fontSize: '2rem',
        },
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: 0,
        padding: 0,
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: 0,
        padding: 0,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: 0,
        padding: 0,
      },
    },
  },
};
