import Category from '@mui/icons-material/Category';
import Home from '@mui/icons-material/Home';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import User from '@mui/icons-material/VerifiedUser';
import { IconButton } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { iconStyle, StyledNavLink, Wrapper } from './styles';

const MobileNavigationBar = () => {
  const DOWN_900 = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));

  if (DOWN_900) {
    return (
      <Wrapper>
        {list.map(({ href, title }) => (
          <StyledNavLink href={href} key={title}>
            <IconButton sx={iconStyle} />

            {title}
          </StyledNavLink>
        ))}
      </Wrapper>
    );
  }

  return null;
};

const list = [
  { title: 'Home', Icon: Home, href: '/' },
  { title: 'Category', Icon: Category, href: '/mobile-category-nav' },
  { title: 'Cart', Icon: ShoppingBag, href: '/cart' },
  { title: 'Account', Icon: User, href: '/profile' },
];

export default MobileNavigationBar;
