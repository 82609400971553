'use client';
import { Stack, Typography, styled } from '@mui/material';

import PopupLayout from 'layouts/Popup-Layout';
import { typography } from 'theme/typography';
import Registration from '../Forms/registration';
import Logo from '../Logo';

const RegistrationForm = () => {
  const RightPanelContainer = styled(Stack)(({ theme }) => ({
    justifyContent: 'start',
    padding: '2%',

    [theme.breakpoints.down('sm')]: { width: '100%' },
  }));

  return (
    <PopupLayout active={'registration'}>
      <RightPanelContainer spacing={3}>
        <Logo direction="column" />
        <Stack spacing={2} width={'100%'}>
          <Stack spacing={2}>
            <Typography
              sx={{ fontSize: { sm: '1.7rem', xs: '1rem' } }}
              fontStyle={'normal'}
              fontFamily={typography.fontFamily}
              fontWeight={500}
              variant="h4"
              textAlign="center"
            >
              Create Your Account
            </Typography>

            <Typography
              variant="body1"
              fontFamily={typography.fontFamily}
              fontSize="1rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              textAlign="center"
              color={'#7B7B7B'}
            >
              Please enter your details or select your login method.
            </Typography>
          </Stack>

          <Registration />
        </Stack>
      </RightPanelContainer>
    </PopupLayout>
  );
};

export default RegistrationForm;
