'use client';
import Link from 'next/link';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import styled from '@mui/material/styles/styled';

export const OuterBox = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  backgroundColor: '#F1EFFF',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const FooterBox = styled('footer')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  padding: '2rem 1.5rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  [theme.breakpoints.up('lg')]: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2rem 2rem',
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: '2rem',
  },
  gap: '1rem',
}));

export const Title = styled('h3')(() => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: 'normal',
}));

export const SpanTitle = styled('span')(() => ({
  fontSize: '1.rem',
  fontWeight: '400',
  lineHeight: 'normal',
}));

export const SubItems = styled(Typography)(() => ({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const InputWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '2.625rem',
  border: '1px solid rgba(237, 237, 237, 0.5)',
  borderRadius: '0.25rem',
  background: 'rgba(237, 237, 237, 0.50)',
}));

export const SubscribeButton = styled('button')(({ theme }) => {
  return {
    height: '2.625rem',
    width: '7.96rem',
    border: 'none',
    borderRadius: '0.25rem',
    color: 'white',
    fontWeight: '500',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  };
});

export const MailInput = styled('input')(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    border: 'none',
    fontSize: '0.92rem',
  };
});

export const FooterNav = styled('div')(() => {
  return {
    width: '100%',
    height: '3.5rem',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const StyledLink = styled(Link)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  };
});
