import Image from 'next/image';

import { Box, Button, Stack, Typography } from '@mui/material';

import Logo from 'libs/components/Logo';
import { DialogDrawer } from 'libs/components/dialog-drawer';
import { FlexBox } from 'libs/components/flex-box';
import { themeColors } from 'theme/theme-colors';

const FavouriteTemplate = () => {
  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {}}
      wrapperStyle={{ height: '100%', width: '100%' }}
    >
      <Stack
        spacing={2}
        alignItems={'center'}
        padding={'3%'}
        maxWidth={{ lg: '25vw', md: '35vw', sm: '50vw', xs: '100%' }}
        sx={{
          maxHeight: '80dvh',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Logo />

        <Box
          position={'relative'}
          sx={{
            margin: 'auto',
          }}
        >
          <Image
            src={'assets/images/popup-images/Rectangle 146 (1).svg'}
            alt="Image"
            height={200}
            width={500}
            sizes="100dvw"
            style={{
              display: 'block',
              maxWidth: '100%',
            }}
          />
        </Box>

        <FlexBox alignItems={'center'} justifyContent={'center'}>
          <Stack spacing={1} justifyContent={'center'}>
            <Typography
              color="#404040"
              fontFamily="Inter"
              fontSize="1rem"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="normal"
            >
              Lorem Ipsum
            </Typography>
            <Typography
              color="#404040"
              fontFamily="Inter"
              fontSize="0.75rem"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="normal"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&aposs standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book.
            </Typography>
          </Stack>
        </FlexBox>

        <FlexBox justifyContent={'center'} gap={'2rem'}>
          <Button
            size="medium"
            sx={{
              borderRadius: '0.375rem',
              border: '1px solid #6E5BE4',
              background: '#FFF',
              color: themeColors.info[800],
              width: '8rem',
            }}
          >
            Buy Now
          </Button>
          <Button
            size="medium"
            sx={{
              borderRadius: '0.375rem',
              background: themeColors.info[800],
              color: '#fff',
              width: '8rem',
            }}
          >
            Subsribe
          </Button>
        </FlexBox>
      </Stack>
    </DialogDrawer>
  );
};

export default FavouriteTemplate;
