import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

import { UserVerifyResponse } from 'libs/dt-types/response-types/user-details';
import { requestor } from 'libs/helpers/api/requestor';
import useAuth from './useAuth';

export const verifyUserDetails = async () => {
  try {
    const data = await requestor.get<UserVerifyResponse>('users/userDetails');

    return data;
  } catch (error: any) {
    console.log(error.message);

    return;
  }
};

export const useLogin = () => {
  const { dispatch } = useAuth();
  const token = Cookies.get('accesstoken');
  const { data } = useQuery(['verifyToken', token], verifyUserDetails, {
    enabled: !!token,
    onSuccess(data) {
      dispatch({
        type: 'LOGIN',
        payload: {
          email: data?.data?.email as string,
          role: data?.data?.role as string,
          id: data?.data?.id as string,
          isVerified: true,
        },
      });
    },
    onError: (err: any) => {
      if (err?.response?.status === 401) {
        Cookies.remove('accesstoken');
      }
    },
  });

  return {
    data,
  };
};
