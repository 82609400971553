import { Fragment } from 'react';

import { Stack } from '@mui/material';

import useAuth from 'hooks/useAuth';
import { SubItems, Title } from './style';

const category = [
  {
    name: 'Help center',
    href: '/contact',
  },
  {
    name: 'Become an affiliate',
    href: '/affiliate',
  },
  {
    name: 'Become an author',
    href: '/sell-your-content',
  },
  {
    name: 'Licence',
    href: '/licence',
  },
];

const Links = () => {
  const { state } = useAuth();

  return (
    <Stack alignSelf="start" spacing={1}>
      <Title>Helpful Links</Title>
      {category.map((item, index) => {
        item.href =
          item.name === 'Become an author' &&
          state?.user?.role?.toLowerCase() === 'author'
            ? '/author'
            : item.href;

        item.name =
          item.name === 'Become an author' &&
          state?.user?.role?.toLowerCase() === 'author'
            ? 'View DashBoard'
            : item.name;

        return (
          <Fragment key={index}>
            <SubItems fontSize={'0.87rem'} fontWeight={400}>
              <a href={item.href}>{item.name}</a>
            </SubItems>
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default Links;
