import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Stack, Typography } from '@mui/material';

import { DialogDrawer } from 'libs/components/dialog-drawer';
import { FlexBox } from 'libs/components/flex-box';

const PremiumSubscriptionPopup = () => {
  return (
    <DialogDrawer
      dialogOpen
      toggleDialog={() => {}}
      wrapperStyle={{ height: '100%', width: '100%', padding: 0 }}
    >
      <Stack
        maxWidth={{ sm: '50vw', xs: '100%' }}
        paddingBottom={0}
        spacing={3}
        position={'relative'}
        sx={{ background: '#FFFDFC' }}
      >
        <Box
          sx={{
            background:
              'linear-gradient(89deg, #FF5CA1 1.83%, #9747FF 118.8%, #6F64FF 129.8%)',
            zIndex: 2,
            height: '5dvh',
          }}
        ></Box>

        <Stack paddingLeft={'2%'} paddingRight={'2%'} spacing={2}>
          <Typography
            style={{
              fontSize: '1.25rem',
              fontWeight: '600',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              color: 'transparent',
              background: 'linear-gradient(90deg, #FF2C84, #6F64FF)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
            }}
          >
            Become a Premium member today!
          </Typography>
          <Stack
            alignItems={'start'}
            sx={{
              color: '#404040',
              fontFamily: 'Poppins',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                background:
                  'linear-gradient(90deg, #FF2C84 2.44%, #6F64FF 107.56%)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Switch to yearly to get 66% off
            </Typography>
            <Typography
              color="#404040"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="0.875rem"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="normal"
              textTransform="capitalize"
            >
              <span
                style={{
                  fontSize: '2.25rem',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  color: 'transparent',
                  background: 'linear-gradient(90deg, #FF2C84, #6F64FF)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                }}
              >
                $16
              </span>{' '}
              &nbsp;&nbsp; <span>Per Month</span>
            </Typography>
          </Stack>
          <Stack>
            <FlexBox justifyContent={'space-between'}>
              <Typography
                color="#6F64FF"
                textAlign="center"
                fontFamily="Poppins"
                fontSize="0.875rem"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="normal"
                textTransform="capitalize"
              >
                Thousand of Free template
              </Typography>
              <Box>
                <DoneIcon />
              </Box>
            </FlexBox>
            <FlexBox justifyContent={'space-between'}>
              <Typography>Every day new templates</Typography>
              <Box>
                <DoneIcon />
              </Box>
            </FlexBox>
            <FlexBox justifyContent={'space-between'}>
              <Typography>Premium template</Typography>
              <Box>
                <DoneIcon />
              </Box>
            </FlexBox>
            <FlexBox justifyContent={'space-between'}>
              <Typography>Daly download</Typography>
              <Box>30</Box>
            </FlexBox>
            <FlexBox justifyContent={'space-between'}>
              <Typography>Priority Support</Typography>
              <Box>24/7</Box>
            </FlexBox>
          </Stack>
        </Stack>
        <Box sx={{ background: '#F2F1FB' }} textAlign={'center'} padding={'2%'}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: '16.25rem',
            }}
          >
            Subscribe
          </Button>
        </Box>
      </Stack>
    </DialogDrawer>
  );
};

export default PremiumSubscriptionPopup;
